/* eslint-disable no-useless-escape */
export const APPROVAL_TYPES = {
  AUTO: 'AUTO',
  ON_REVIEW: 'ON_REVIEW',
};

export const TARGET_USER = {
  BANDS: 'BANDS',
  INDIVIDUALS: 'INDIVIDUALS',
};

export const VIDEO_SOURCES = [
  {
    regex: /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
    type: 'YOUTUBE',
    result: 7,
    resultLength: 11,
  },
  {
    regex: /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i,
    type: 'VIMEO',
    result: 1,
    resultLength: 9,
  },
];

export const SORT_ENUM = {
  DATE: 'DATE',
  DEADLINE: 'DEADLINE',
  RECENT: 'RECENT',
  ALPHABET: 'ALPHABET',
  PRICE: 'PRICE',
};

export const OPP_STATUS_ENUM = {
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  PUBLISHED: 'PUBLISHED',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  DELETED: 'DELETED',
  ARCHIVED: 'ARCHIVED',
};
